<template>

<div class="w-full z-50 bg-white border-b border-1 fixed top-0">
  <div class="relative z-10 container">
    <div class="flex items-center justify-between px-4 py-5 mx-auto sm:px-0 sm:py-4 xl:justify-start xl:space-x-10">
      <div>
        <a href="/" class="flex">
          <span class="font-logo text-2xl text-blue-brand">ANGLING FRANCE</span>
        </a>
      </div>
      <div class="-my-2 -mr-2 xl:hidden">
        <button type="button" class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500" @click="mobileOpen = !mobileOpen">
          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <div class="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between xl:space-x-12">
        <nav class="flex space-x-10">
          <div class="relative">
            <button 
              type="button" 
              :class="[ourLakesIsOn ? 'text-gray-900' : 'text-gray-500' , checkSegment('carp-fishing-in-france') ]" 
              @mouseover="ourLakesIsOn = !ourLakesIsOn"
              @click="ourLakesIsOpen = !ourLakesIsOpen"
              class="inline-flex items-center space-x-2 text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out group hover:text-gray-900 focus:outline-none focus:text-gray-900 border-blue-brand border px-4 py-2 rounded-lg">
              <span>Our Lakes</span>
              <svg 
                :class="ourLakesIsOn ? 'text-gray-600' : 'text-gray-400'" 
                @mouseover="ourLakesIsOn = !ourLakesIsOn"
                class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <a href="/about-us" class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900 border-blue-brand border px-4 py-2 rounded-lg" :class="checkSegment('about-us')">
            About Us
          </a>
          <div class="relative">
            <button 
              type="button" 
              :class="[informationIsOn ? 'text-gray-900' : 'text-gray-500' , checkSegment('booking-a-holiday') ]" 
              @mouseover="informationIsOn = !informationIsOn"
              @click="informationIsOpen = !informationIsOpen"
              class="inline-flex items-center space-x-2 text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out group hover:text-gray-900 focus:outline-none focus:text-gray-900 border-blue-brand border px-4 py-2 rounded-lg">
              <span>How to Book</span>
              <svg 
                :class="informationIsOn ? 'text-gray-600' : 'text-gray-400'" 
                @mouseover="informationIsOn = !informationIsOn"
                class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <a href="/news" class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900 border-blue-brand border px-4 py-2 rounded-lg" :class="checkSegment('news')" >
            News
          </a>          
        </nav>
        <div class="flex items-center space-x-8">


          <div class="relative">
            <button 
              type="button" 
              :class="[contactIsOn ? 'text-gray-900' : 'text-gray-500' , checkSegment('booking-a-holiday') ]" 
              @mouseover="contactIsOn = !contactIsOn"
              @click="contactIsOpen = !contactIsOpen"
              class="inline-flex items-center space-x-2 text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out group hover:text-gray-900 focus:outline-none focus:text-gray-900 border-blue-brand border px-4 py-2 rounded-lg">
              <span>Contact</span>
              <svg 
                :class="contactIsOn ? 'text-gray-600' : 'text-gray-400'" 
                @mouseover="contactIsOn = !contactIsOn"
                class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <div class="relative inline-block text-left">
            <div>
              <span class="rounded-md shadow-sm">
                <button type="button" class="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-brand border border-transparent rounded-md" id="options-menu" aria-haspopup="true" aria-expanded="true"
                @click="bookNowOpen = !bookNowOpen">
                  BOOK NOW
                  <svg class="w-5 h-5 ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
              </span>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <div class="absolute right-0 w-56 mt-2 origin-top-right bg-blue-brand rounded-md shadow-lg" v-show="bookNowOpen">
                <div class="bg-blue-brand rounded-md shadow-xs">
                  <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a :href="lake.availabilityLink" class="block px-4 py-2 text-sm leading-5 text-white hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem" v-for="lake in availabilityLinks" :key="lake.id">{{ lake.title }}</a>
                  </div>
                </div>
              </div>
            </transition>

          </div>
        </div>
      </div>
    </div>
  </div>


  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-class="-translate-y-1 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-1 opacity-0"
  >
    <div class="absolute w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 xl:ml-0 xl:left-1/2 xl:-translate-x-96" v-show="ourLakesIsOpen">
      <div class="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="relative z-20 grid gap-4 px-2 py-6 bg-white sm:gap-8 sm:p-8">
          <a :href="lake.link" class="flex items-start p-1 -m-3 rounded-lg hover:bg-gray-50" v-for="lake in lakes" :key="lake.id">
            <div class="ml-0">
              <p class="text-base font-medium text-gray-900">
                {{ lake.title }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </transition>

  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-class="-translate-y-1 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-1 opacity-0"
  >
    <div class="absolute w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 xl:ml-0 xl:left-1/2 xl:-translate-x-1/2" v-show="informationIsOpen">
      <div class="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="relative z-20 grid gap-4 px-2 py-6 bg-white sm:gap-8 sm:p-8">
          <a :href="bookingAHolidayPage.link" class="flex items-start p-1 -m-3 rounded-lg hover:bg-gray-50" v-for="bookingAHolidayPage in bookingaholidaypages" :key="bookingAHolidayPage.id">
            <div class="ml-0">
              <p class="text-base font-medium text-gray-900">
                {{ bookingAHolidayPage.title }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </transition>


  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-class="-translate-y-1 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-1 opacity-0"
  >
    <div class="absolute max-w-md px-2 mt-3 -ml-4 transform sm:px-0 xl:ml-0 xl:left-4/5 xl:-translate-x-4/5" v-show="contactIsOpen">
      <div class="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="relative z-20 grid gap-4 px-2 py-6 bg-white sm:gap-8 sm:p-8">
          <a href="/contact-us" class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Contact Us
          </a>
          <a :href=telLink class="text-base font-medium leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Phone Us
          </a>
        </div>
      </div>
    </div>
  </transition>


  <!-- Mobile menu, show/hide based on mobile menu state. -->
  <transition
    enter-active-class="duration-200 ease-out"
    enter-class="scale-95 opacity-0"
    enter-to-class="scale-100 opacity-100"
    leave-active-class="duration-100 ease-in"
    leave-class="scale-100 topacity-100"
    leave-to-class="scale-95 opacity-0"
  >
    <div class="absolute inset-x-0 top-0 z-20 p-2 transition origin-top-right transform xl:hidden" v-show="mobileOpen">
      <div class="rounded-lg shadow-lg">
        <div class="bg-white divide-y-2 rounded-lg shadow-xs divide-gray-50">
          <div class="px-5 pt-5">
            <div class="flex items-center justify-between">
              <div>
                <span class="font-logo text-2xl text-teal-500">&nbsp;</span>
              </div>
              <div class="-mr-2">
                <button type="button" class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500" @click="mobileOpen = !mobileOpen">
                  <!-- Heroicon name: x -->
                  <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            <div class="flex items-center justify-between flex-col pb-4">
              <h2 class="text-blue-brand font-bold">Our Lakes</h2>
              <a :href="lake.link" class="text-base leading-6 text-gray-900 transition duration-150 ease-in-out rounded-md hover:text-gray-700" v-for="lake in lakes" :key="lake.id">
                {{lake.title}}
              </a>

              <br />

              <h2 class="text-blue-brand font-bold">How to Book</h2>
              <a :href="bookingAHolidayPage.link" class="text-base leading-6 text-gray-900 transition duration-150 ease-in-out rounded-md hover:text-gray-700" v-for="bookingAHolidayPage in bookingaholidaypages" :key="bookingAHolidayPage.id">
                {{ bookingAHolidayPage.title }}
              </a>              

            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>




</div>

</template>

<script>
  export default {
    props : {
      lakes : '',
      bookingaholidaypages : '',
      telephone: '',
      activesegments: ''
    },
    data: () => ({
      ourLakesIsOn: false,
      ourLakesIsOpen: false,
      informationIsOn: false,
      informationIsOpen: false,
      bookNowOpen : false,
      mobileOpen : false,
      contactIsOn : false,
      contactIsOpen : false,
    }),
    computed: {
      availabilityLinks: function () {
        return this.lakes.filter(i => i.availabilityLink !== '')
      },
      telLink: function () {
        return 'tel:'+this.telephone
      },
    },
    methods: {
      checkSegment: function(segment){
        // console.log(segment,this.activesegments);
        if(this.activesegments.includes(segment)){
          return ' font-bold border-b-4 border-blue-brand ';
        }
      }
    }
  }
</script>