import Vue from "vue";
import Components from "./components";
import "./app.scss";
// import "tiny-slider/dist/tiny-slider.css";
import { tns } from "tiny-slider/src/tiny-slider";
import imagesLoaded from 'imagesloaded/imagesloaded';
import Masonry from "masonry-layout/masonry";
// import "glightbox/dist/css/glightbox.css";
import GLightbox from 'glightbox/dist/js/glightbox';
import { setTimeout } from "core-js";


Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});

if(document.getElementsByClassName('slideshow').length) {
  var slideshowSlider = tns({
    container: '.slideshow',
    items: 1,
    autoHeight : true,
    center: true,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    responsive: {
      640: {
        edgePadding: 20,
        gutter: 20,
        items: 2
      },
      700: {
        gutter: 30
      },
      900: {
        items: 3
      }
    }
  });
}

if(document.getElementsByClassName('reviews-slider').length) {
  var reviewsSlider = tns({
    container: '.reviews-slider',
    items: 1,
    autoHeight : true,
    center: true,
    controls: false,
    nav: true,
    navPosition: 'bottom',
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayButtonOutput :false
  });
  setTimeout(() => {
    reviewsSlider.updateSliderHeight();
  }, 750);
}

if(document.getElementsByClassName('contentblock-slider').length) {
  var contentBlockSlider = tns({
    container: '.contentblock-slider',
    items: 1,
    center: true,
    controls: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayButtonOutput :false
  });  
}

if(document.getElementsByClassName('masonry-grid').length) {
  
  var $grid = document.querySelector('.masonry-grid');
  var msnry = new Masonry( $grid, {
    itemSelector: '.grid-item'
  });        

  imagesLoaded( $grid, function( instance ) {
    msnry.layout();
    setInterval(() => {
      msnry.layout();
    }, 500);
  });
  
}

if(document.getElementsByClassName('tab-pane').length) {
  // store tabs variable
  var myTabs = document.querySelectorAll(".tab-btn");
  function myTabClicks(tabClickEvent) {
    
    for (var i = 0; i < myTabs.length; i++) {
      myTabs[i].classList.remove("active");
    }
    
    var clickedTab = tabClickEvent.currentTarget;
    clickedTab.classList.add("active");
    tabClickEvent.preventDefault();
    var myContentPanes = document.querySelectorAll(".tab-pane");
    
    for (i = 0; i < myContentPanes.length; i++) {
      myContentPanes[i].classList.add("hidden");
    }

    var anchorReference = tabClickEvent.target;
    var activePaneId = anchorReference.getAttribute("href");
    var activePane = document.querySelector(activePaneId);
    activePane.classList.remove("hidden");

  }
  for (let i = 0; i < myTabs.length; i++) {
    myTabs[i].addEventListener("click", myTabClicks)
  }
}

const lightbox = GLightbox();